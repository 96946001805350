var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"flex-nowrap",style:(_vm.$vuetify.breakpoint.mdAndUp
      ? 'height: calc(100vh - 64px)'
      : 'height: 100%'),attrs:{"dense":"","no-gutters":"","align":"stretch","justify":"start"}},[_c('v-col',{staticStyle:{"max-width":"300px","height":"100%"}},[_c('v-list',{staticClass:"pa-0",staticStyle:{"height":"100%","position":"relative"}},[_c('v-subheader',{staticStyle:{"position":"relative","height":"85px"}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(("$vuetify.devices." + (_vm.deviceType.toUpperCase()))))+" "),_c('v-spacer',{staticStyle:{"min-width":"24px"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.showDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiPlaylistPlus))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( ("$vuetify.devices.ADD_" + (_vm.deviceType.toUpperCase())) ))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.getDeviceList}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiRefresh))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.devices.REFRESH'))+" ")])])],1),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","single-line":"","autofocus":"","label":_vm.$vuetify.lang.t('$vuetify.devices.SEARCH'),"placeholder":_vm.$vuetify.lang.t('$vuetify.devices.SEARCH'),"type":"search"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticStyle:{"margin":"6px","margin-bottom":"5px"},attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiMagnify)+" ")])]},proxy:true}]),model:{value:(_vm.searchList),callback:function ($$v) {_vm.searchList=$$v},expression:"searchList"}})],1)],1)],1),(_vm.loading)?_c('v-progress-circular',{staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%)"},attrs:{"indeterminate":"","color":"primary"}}):(!_vm.filteredItems.length)?_c('div',{staticClass:"pt-4",style:(("text-align: center;\n          color: " + (_vm.$vuetify.theme.dark
              ? 'rgba(255, 255, 255, 0.5)'
              : 'rgba(0, 0, 0, 0.38)') + ";\n          font-size: 0.875rem;"))},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.dataIterator.noResultsText'))+" ")]):_c('v-list-item-group',{style:(_vm.$vuetify.breakpoint.mdAndUp
            ? 'height: calc(100vh - 149px); position: relative; overflow: auto'
            : 'position: relative'),attrs:{"color":"primary"}},_vm._l((_vm.filteredItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.link + (_vm.searchList ? ("?search=" + _vm.searchList) : '')}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.state
                      ? item.state.state
                        ? 'success'
                        : 'error'
                      : undefined}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.state ? item.state.state ? _vm.mdiCheckNetwork : _vm.mdiCloseNetwork : _vm.mdiHelpNetwork)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.state ? item.state.state ? ((_vm.$vuetify.lang.t( '$vuetify.devices.STATE_CONNECTED_SINCE' )) + " " + (_vm.$moment(item.state.lastConnectAt).fromNow())) : ((_vm.$vuetify.lang.t( '$vuetify.devices.STATE_DISCONNECTED_SINCE' )) + " " + (_vm.$moment(item.state.lastDisconnectAt).fromNow())) : _vm.$vuetify.lang.t('$vuetify.devices.STATE_UNKNOWN'))+" ")])])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){$event.preventDefault();_vm.removeUid = item.text}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.$route.path.includes(item.link) ? 'primary' : undefined}},[_vm._v(" "+_vm._s(_vm.mdiClose)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( ("$vuetify.devices.REMOVE_" + (_vm.deviceType.toUpperCase())) ))+" ")])])],1)],1)}),1)],1)],1),_c('v-divider',{staticClass:"ma-0",attrs:{"vertical":""}}),_c('v-col',{style:(!_vm.$route.path.split('/')[2] ? 'max-width: 0' : '')},[_c('router-view',{key:_vm.$route.path.split('/')[2]})],1),(
      _vm.showConsole && _vm.$vuetify.breakpoint.mdAndUp && _vm.$route.path.split('/')[2]
    )?_c('v-divider',{staticClass:"ma-0",staticStyle:{"right":"0","position":"sticky","z-index":"2"},attrs:{"vertical":""}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500"},on:{"click:outside":_vm.resetDialog},model:{value:(_vm.dialogShow),callback:function ($$v) {_vm.dialogShow=$$v},expression:"dialogShow"}},[_c('v-card',[_c('v-form',{ref:"form",model:{value:(_vm.dialogValid),callback:function ($$v) {_vm.dialogValid=$$v},expression:"dialogValid"}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( ("$vuetify.devices.ADD_" + (_vm.deviceType.toUpperCase())) ))+" ")]),_c('v-spacer'),(_vm.hasWebcam)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.scannerShow = !_vm.scannerShow}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.scannerShow ? _vm.mdiFormTextbox : _vm.mdiQrcodeScan))])],1)]}}],null,false,1642966110)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( _vm.scannerShow ? '$vuetify.devices.HIDE_SCANNER' : '$vuetify.devices.SHOW_SCANNER' ))+" ")])]):_vm._e()],1),_c('v-card-text',[(_vm.scannerShow)?[_c('QrcodeStream',{attrs:{"track":_vm.scannerError ? _vm.paintError : _vm.paintOutline},on:{"init":_vm.scannerInit,"decode":_vm.decodeQR}}),(_vm.scannerLoading)?_c('v-progress-circular',{staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%)"},attrs:{"indeterminate":"","color":"primary"}}):_vm._e()]:[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.devices.UID'),"placeholder":_vm.$vuetify.lang.t('$vuetify.devices.UID'),"rules":_vm.requiredRules,"required":"","autofocus":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
    var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.dialogUid),callback:function ($$v) {_vm.dialogUid=$$v},expression:"dialogUid"}}),_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.devices.VERIFICATION'),"placeholder":_vm.$vuetify.lang.t('$vuetify.devices.VERIFICATION'),"rules":_vm.requiredRules,"required":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
    var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.dialogVerification),callback:function ($$v) {_vm.dialogVerification=$$v},expression:"dialogVerification"}})]],2),_c('v-card-actions',[_c('small',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.devices.REQUIRED_FIELD'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.resetDialog}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.devices.CANCEL'))+" ")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.loading,"disabled":_vm.scannerShow || _vm.loading || !_vm.dialogValid},on:{"click":_vm.addDevice}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.devices.ADD'))+" ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"value":!!_vm.removeUid,"max-width":"400"},on:{"input":function($event){_vm.removeUid = ''}}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( ("$vuetify.devices.REMOVE_" + (_vm.deviceType.toUpperCase())) ))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.removeUid)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( ("$vuetify.devices.CONFIRM_REMOVE_" + (_vm.deviceType.toUpperCase())) ))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.removeUid = ''}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.devices.CANCEL'))+" ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.removeDevice}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.devices.REMOVE'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }