





















































































































































































































































































































































import { ConnectionState, DeviceType } from '../../typings';
import {
  getDeviceList,
  addDevice,
  removeDevice,
  getConnectionState
} from '../../api/user';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  mdiPlaylistPlus,
  mdiRefresh,
  mdiClose,
  mdiMagnify,
  mdiQrcodeScan,
  mdiFormTextbox,
  mdiCheckNetwork,
  mdiCloseNetwork,
  mdiHelpNetwork
} from '@mdi/js';
import type { ValidationRules } from '../../typings';
import { noop } from 'vue-class-component/lib/util';
import { getModule } from 'vuex-module-decorators';
import { SettingsModule } from '../../plugins/store';
import { QrcodeStream } from 'vue-qrcode-reader';
import { Hub } from '@aws-amplify/core';

const settingsStore: SettingsModule = getModule(SettingsModule);

interface ListItem {
  text: string;
  link: string;
  state?: ConnectionState;
}

@Component({
  components: {
    QrcodeStream
  }
})
export default class DeviceListView extends Vue {
  private readonly DeviceType: typeof DeviceType = DeviceType;
  private readonly mdiRefresh: string = mdiRefresh;
  private readonly mdiMagnify: string = mdiMagnify;
  private readonly mdiPlaylistPlus: string = mdiPlaylistPlus;
  private readonly mdiClose: string = mdiClose;
  private readonly mdiQrcodeScan: string = mdiQrcodeScan;
  private readonly mdiFormTextbox: string = mdiFormTextbox;
  private readonly mdiCloseNetwork: string = mdiCloseNetwork;
  private readonly mdiCheckNetwork: string = mdiCheckNetwork;
  private readonly mdiHelpNetwork: string = mdiHelpNetwork;
  private readonly noop: typeof noop = noop;
  private readonly requiredRules: ValidationRules = [
    (v: unknown): true | string => !!v || '$vuetify.devices.REQUIRED'
  ];

  private loading: boolean = true;
  private hasWebcam: boolean = false;
  private scannerShow: boolean = false;
  private scannerError: boolean = false;
  private scannerLoading: boolean = false;
  private dialogShow: boolean = false;
  private dialogValid: boolean = false;
  private dialogUid: string = '';
  private dialogVerification: string = '';
  private removeUid: string = '';
  private searchList: string = '';
  private items: ListItem[] = [];

  private get showConsole(): boolean {
    return settingsStore.console;
  }

  private get filteredItems(): ListItem[] {
    return this.items.filter(
      (item: ListItem): boolean =>
        !this.searchList ||
        JSON.stringify(item)
          .toUpperCase()
          .includes(this.searchList.toUpperCase())
    );
  }

  @Prop({
    type: String,
    required: true,
    validator: (value: DeviceType): boolean =>
      Object.values(DeviceType).includes(value)
  })
  public deviceType!: DeviceType;

  private mounted(): void {
    this.setHasWebcam();
    this.getDeviceList();
  }

  private async setHasWebcam(): Promise<void> {
    if (typeof navigator?.mediaDevices?.enumerateDevices === 'function') {
      navigator.mediaDevices
        .enumerateDevices()
        .then(
          (devices: MediaDeviceInfo[]): void =>
            void (this.hasWebcam = devices.some(
              (device: MediaDeviceInfo): boolean =>
                device.kind.includes('video')
            ))
        )
        .catch(noop);
    }
  }

  private showError(message: string): void {
    Hub.dispatch('appAlert', {
      event: 'error',
      message
    });
  }

  private showDialog(): void {
    this.dialogShow = true;
    if (
      !this.hasWebcam &&
      typeof navigator?.mediaDevices?.getUserMedia === 'function'
    ) {
      navigator.mediaDevices
        .getUserMedia({
          video: true
        })
        .then(this.setHasWebcam)
        .catch(noop);
    }
  }

  private getDeviceList(): void {
    if (!this.deviceType) {
      return;
    }
    this.loading = true;
    getDeviceList(this.deviceType)
      .then(async (items: string[]): Promise<void> => {
        this.items = await Promise.all(
          items.map(
            (uid: string): Promise<ListItem> =>
              getConnectionState(this.deviceType, uid)
                .catch((): undefined => undefined)
                .then(
                  (state: ConnectionState | undefined): ListItem => ({
                    text: uid,
                    link: `/${this.deviceType}/${uid}`,
                    state
                  })
                )
          )
        );
      })
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private paintOutline(
    detectedCodes: Array<{ cornerPoints: Array<{ x: number; y: number }> }>,
    ctx: CanvasRenderingContext2D
  ): void {
    for (const detectedCode of detectedCodes) {
      const [firstPoint, ...otherPoints]: Array<{ x: number; y: number }> =
        detectedCode.cornerPoints;

      ctx.strokeStyle = 'green';
      ctx.lineWidth = 4;

      ctx.beginPath();
      ctx.moveTo(firstPoint.x, firstPoint.y);
      for (const { x, y } of otherPoints) {
        ctx.lineTo(x, y);
      }
      ctx.lineTo(firstPoint.x, firstPoint.y);
      ctx.closePath();
      ctx.stroke();
    }
  }

  private paintError(
    detectedCodes: Array<{ cornerPoints: Array<{ x: number; y: number }> }>,
    ctx: CanvasRenderingContext2D
  ): void {
    for (const detectedCode of detectedCodes) {
      const [firstPoint, ...otherPoints]: Array<{ x: number; y: number }> =
        detectedCode.cornerPoints;

      ctx.strokeStyle = 'red';
      ctx.lineWidth = 4;

      ctx.beginPath();
      ctx.moveTo(firstPoint.x, firstPoint.y);
      for (const { x, y } of otherPoints) {
        ctx.lineTo(x, y);
      }
      ctx.lineTo(firstPoint.x, firstPoint.y);
      ctx.lineTo(otherPoints[1].x, otherPoints[1].y);
      ctx.closePath();
      ctx.stroke();
    }
  }

  private decodeQR(text: string): void {
    let json: { uid: string; verification: string };
    try {
      json = JSON.parse(text);
      if (!json || !json.uid || !json.verification) {
        throw json;
      }
    } catch (e) {
      this.scannerError = true;
      return;
    }
    this.scannerError = false;
    setTimeout((): void => {
      this.dialogUid = json.uid;
      this.dialogVerification = json.verification;
      this.scannerShow = false;
    }, 1500);
  }

  private async scannerInit(init: Promise<unknown>): Promise<void> {
    this.scannerLoading = true;
    try {
      await init;
    } catch (e) {
      this.showError(e.message);
      this.scannerShow = false;
    } finally {
      this.scannerLoading = false;
    }
  }

  private resetDialog(): void {
    this.dialogShow = false;
    this.scannerShow = false;
    this.dialogUid = '';
    this.dialogVerification = '';
  }

  private addDevice(): void {
    if (!this.deviceType) {
      return;
    }
    this.loading = true;
    addDevice(this.deviceType, this.dialogUid, this.dialogVerification)
      .then((): void => {
        this.resetDialog();
        this.getDeviceList();
      })
      .catch((error: Error): void => {
        this.dialogShow = false;
        this.loading = false;
        this.showError(error.message);
      });
  }

  private removeDevice(): void {
    if (!this.deviceType || !this.removeUid) {
      return;
    }
    const uid: string = this.removeUid;
    this.loading = true;
    this.removeUid = '';
    removeDevice(this.deviceType, uid)
      .then((): void => {
        this.getDeviceList();
        this.$router.replace(`/${this.deviceType}`).catch(noop);
      })
      .catch((error: Error): void => {
        this.loading = false;
        this.showError(error.message);
      });
  }
}
